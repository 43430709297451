<template>
    <div>
        <survey-subtitle v-if="title" v-t="title" />
        <content-wrapper tag="p" v-if="content" v-t="content" />

        <content-wrapper inverse>
            <table style="width: 100%">
                <tr>
                    <td>
                        {{
                            $t(`${type}.${categoryKey}_SCORE_DESC`, {
                                context,
                                teamMedian: scores.teamMedian,
                            })
                        }}
                    </td>
                    <td v-if="options.length" style="width: 160px; float: right;">
                        <survey-select
                            id="demographic-selector"
                            :value="compareTo"
                            @change="onChange($event)"
                            :disabled="Object.keys(secondaryScores).length !== 0"
                        >
                            <option v-if="compareTo == 'default'" value="default">All respondents</option>
                            <option v-else value="">All respondents</option>
                            <option v-for="option in options" :key="option.label" :value="option.label">{{ option.question }}</option>
                        </survey-select>
                    </td>
                </tr>
            </table>
        </content-wrapper>

        <bar-chart-wrapper v-if="loading">
            <survey-loader cover />
        </bar-chart-wrapper>
        <div v-else-if="verticalDistribution">
            <div>
                <percentage-distribution-chart :chartData="distributions" :chartOptions="distributionChartOptions" />
                <div class="dist-quarters">
                    <span v-if="quarterMiddle" class="middle">{{ quarterMiddle }}</span>
                    <span
                        v-for="(quarter, index) in quarters"
                        :key="index"
                        :class="getQuarterPosition(index, quarters.length)"
                        :style="`${getQuarters(index, quarters.length)}`"
                        >{{ quarter }}</span
                    >
                </div>
                <div class="dist-annotations">
                    <survey-translate tag="span" base="reportsWeb" name="DISTRIBUTION_LOW_DESC" raw />
                    <survey-translate tag="span" base="reportsWeb" name="DISTRIBUTION_HIGH_DESC" raw />
                </div>
            </div>
        </div>
        <div v-if="!showDeviation && !verticalDistribution" class="deviation-wrapper">
            <bar-chart-wrapper
                v-if="!compareTo"
                class="bar-graph"
                :type="type"
                :categoryKey="categoryKey"
                :show-quartiles="showQuartiles"
                :quarters="quarters"
                :disable-iteration-picker="!!compareTo"
                primary
            >
                <bar-chart :scores="scores" primary />
                <bar-chart :scores="secondaryScores" />
            </bar-chart-wrapper>
            <bar-chart-wrapper
                v-else-if="compareTo == 'default'"
                :type="type"
                class="bar-graph"
                :categoryKey="categoryKey"
                :show-quartiles="showQuartiles"
                :quarters="quarters"
                :disable-iteration-picker="!!compareTo"
                primary
            >
                <bar-chart
                    v-for="(score, index) in scores"
                    :key="score.index"
                    :scores="score.scores"
                    :category="categoryKey"
                    stacked
                    :label="`${score.label}`"
                    :color="barColor(index)"
                    :score-scale="quarters[quarters.length - 1]"
                    primary
                />
                <bar-chart
                    v-for="(score, index) in secondaryScores"
                    :key="score.index"
                    :scores="score.scores"
                    :category="categoryKey"
                    stacked
                    :label="`${score.label}`"
                    :color="barColor(index)"
                    :score-scale="quarters[quarters.length - 1]"
                />
            </bar-chart-wrapper>
            <bar-chart-wrapper
                v-else
                class="bar-graph"
                :type="type"
                :show-quartiles="showQuartiles"
                :categoryKey="categoryKey"
                :quarters="quarters"
                :disable-iteration-picker="!!compareTo"
                primary
            >
                <bar-chart
                    v-for="(scores, index) in sortedCompareScores"
                    :key="scores.compareAnswer"
                    :scores="scores"
                    :category="categoryKey"
                    stacked
                    :label="`${scores.compareAnswer} (${scores.groupSize})`"
                    :color="barColor(index)"
                    :score-scale="quarters[quarters.length - 1]"
                />
            </bar-chart-wrapper>
        </div>
        <content-wrapper v-if="explain">
            <p>{{ teamQuartileTranslation }}</p>
            <p>{{ teamDeviationTranslation }}</p>
        </content-wrapper>
        <div v-if="showDeviation" class="deviation-wrapper">
            <bar-chart-wrapper
                v-if="!compareTo || compareTo == 'default'"
                :type="type"
                :quarters="quarters"
                :show-quartiles="showQuartiles"
                :categoryKey="categoryKey"
                hide-iteration-picker
                remove-completion-date
            >
                <bar-chart :scores="scores" :category="categoryKey" primary />
            </bar-chart-wrapper>
            <bar-chart-wrapper
                v-else
                class="bar-graph"
                :type="type"
                :show-quartiles="showQuartiles"
                :quarters="quarters"
                :categoryKey="categoryKey"
                hide-iteration-picker
                remove-completion-date
                primary
            >
                <bar-chart
                    v-for="(scores, index) in sortedCompareScores"
                    :key="scores.compareAnswer"
                    :scores="scores"
                    :category="categoryKey"
                    stacked
                    :label="`${scores.compareAnswer} (${scores.groupSize})`"
                    :color="barColor(index)"
                    :score-scale="quarters[quarters.length - 1]"
                    primary
                />
            </bar-chart-wrapper>
        </div>
        <AccordionList v-if="showDeviation && showDeviationExplainer" v-model:state="state">
            <AccordionItem id="accordion-item-extended-1">
                <template #summary>
                    <survey-translate base="reportsWeb" :name="`${categoryKey}_EXPLAINER_TITLE`" raw />
                </template>
                <template #icon><survey-icon icon="chevron-down"/></template>
                <survey-translate base="reportsWeb" :name="`${categoryKey}_EXPLAINER_DESC`" raw />
            </AccordionItem>
        </AccordionList>

    </div>
</template>
<script src="./TeamCompareQuestions.ts"></script>
<style lang="scss">
.deviation-wrapper {
    display: flex;

    .barchart-emoji {
        width: 10%;
        text-align: center;
        margin: 1rem 0 4rem 0;
        font-size: 3em;
    }

    .survey-bar-wrapper {
        width: 80%;
    }
}

.dist-quarters {
    position: relative;
    margin: auto;
    left: 0;
    width: 80%;
    height: 2rem;
    border-top: 1px black solid;
    z-index: 0;

    span {
        display: inline-block;
        position: absolute;
        top: 0;
        width: 3rem;
        text-align: center;
        padding-top: 1rem;
        margin-left: -1.5rem;

        &::before {
            content: '';
            position: absolute;
            border-left: 1px black solid;
            height: 0.75rem;
            top: 0;
            left: 1.5rem;
        }
    }

    span.middle {
        left: 50%;

        &::before {
            border-left: 0px transparent solid;
        }
    }

    span.first::before,
    span.last::before {
        top: -0.75rem;
        height: 1.5rem;
    }
}

.dist-annotations {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 5rem;

    span {
        position: relative;
        float: left;
        top: 0;
        width: 20%;
        text-align: center;
        font-size: small;
        padding: 10px;
    }
}
</style>
