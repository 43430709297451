<template>
    <report id="epsi">
        <cover-page :name="context" :date="$filters.date(report.dateReport.date)" :participantsCompleted="participantsCompletedInvited" />

        <page>
            <content-wrapper>
                <survey-translate :base="type" name="INTRO_START_TEAM" :args="{ context }" raw />
                <survey-title v-t="`${type}.INTRO_TITLE`" />
                <survey-translate tag="p" :base="type" name="INTRO_TEASER" raw />
            </content-wrapper>
            <team-compare-question
                v-if="!exposed"
                :categoryKey="CategoryType.General"
                :context="context"
                :scores="scores(report, CategoryType.General)"
                :type="'reportsWeb'"
                :options="questionOptions"
                :compare-to="compareTo"
                :compare-scores="compareGeneralScores(CategoryType.General)"
                @compare="compareQuestions"
                :loading="comparing"
                :quarters="['Minimum', 'Maximum']"
                :demographic-table="report.table"
                show-quartiles
            />
            <team-compare-question
                v-else
                :categoryKey="CategoryType.General"
                :context="context"
                :scores="scores(report, CategoryType.General)"
                :type="'reportsWeb'"
                :options="questionOptions"
                :compare-to="compareTo"
                :compare-scores="compareGeneralScores(CategoryType.General)"
                @compare="compareQuestions"
                :loading="comparing"
                :quarters="['Minimum', 'Maximum']"
                :demographic-table="report.table"
                show-quartiles
                show-deviation
            />
        </page>

        <page v-for="key in categoryKeys" :key="`cat_${key}`" page-break>
            <team-compare-question
                v-if="!exposed"
                :categoryKey="CategoryType[key]"
                :context="context"
                :scores="scores(report, CategoryType[key])"
                :type="type"
                :options="questionOptions"
                :compare-to="compareTo"
                :compare-scores="compareGeneralScores(CategoryType[key])"
                @compare="compareQuestions"
                :title="{ path: `${type}.${CategoryType[key]}_ROLE_TITLE`, args: { context } }"
                :content="{ path: `${type}.${CategoryType[key]}_INTRODUCTION`, args: { context } }"
                :loading="comparing"
                :quarters="['Minimum', 'Maximum']"
                :demographic-table="report.table"
                explain
                show-quartiles
            />
            <team-compare-question
                v-else
                :categoryKey="CategoryType[key]"
                :context="context"
                :scores="scores(report, CategoryType[key])"
                :type="type"
                :options="questionOptions"
                :compare-to="compareTo"
                :compare-scores="compareGeneralScores(CategoryType[key])"
                @compare="compareQuestions"
                :title="{ path: `${type}.${CategoryType[key]}_ROLE_TITLE`, args: { context } }"
                :content="{ path: `${type}.${CategoryType[key]}_INTRODUCTION`, args: { context } }"
                :quarters="['Minimum', 'Maximum']"
                :loading="comparing"
                :demographic-table="report.table"
                explain
                show-quartiles
                show-deviation
            />
        </page>

        <page v-if="report.questionScores && report.questionScores.data && report.questionScores.data.length > 0">
            <template v-for="result in epsiQuestionScores" :key="`question_${result.groupName}`">
                <team-compare-question
                    :categoryKey="result.groupName"
                    :context="context"
                    :scores="mapResult(result)"
                    :type="'surveyQuestions'"
                    :options="questionOptions"
                    :compare-to="compareTo"
                    :compare-scores="compareScores(result.groupName)"
                    @compare="compareQuestions"
                    :title="{ path: `surveyQuestions.${result.groupName}`, args: { context } }"
                    :content="{ path: `surveyQuestions.${result.groupName}_DESCRIPTION`, args: { context } }"
                    :quarters="['Minimum', 'Maximum']"
                    :loading="comparing"
                    :demographic-table="report.table"
                    show-deviation
                />
            </template>
        </page>

        <page v-if="exposed">
            <template v-if="report.table && report.table.entries && report.table.entries.length > 0">
                <team-table :categoryKey="'METADATA'" :context="context" :scores="report.table.entries" :type="'reportsWeb'" />
            </template>

            <team-heatmap :categoryKey="'HEATMAP'" :context="context" :type="'reportsWeb'" :heatmap="report.heatmap" />

            <team-compare :context="context" :type="'reportsWeb'" :options="questionOptions" />
        </page>
    </report>
</template>
<script src="./EpsiTeam.ts"></script>
<style lang="scss"></style>
