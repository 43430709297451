import MainLayoutView from "@/generic/views/MainLayoutView";
import Language from "@/store/models/Language";
import { defineComponent, onMounted } from "vue";
import { useStore } from 'vuex';

export default defineComponent({
    extends: MainLayoutView,
    setup() {
        const store = useStore();

        onMounted(async () => {
            const language = new Language(store.getters.language);
            language.section = 'generic';
            await store.dispatch('setLanguage', language);
        });
    },
});
